import React from 'react';
import styles from "./inventory.module.css";
import Modal from "../components/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useState } from 'react';
import addButton from "./images/add.png";
import garbage from "./images/garbage.png";

var ingredients = [];
var fermentables = [];
var hops = [];
var yeasts = [];
var other = [];
var result = null;
//fermentables, hops, yeasts, other
var nullCounter = 0;


const MyBrewstation = () => {

  function add(addedElement, category) {
    ingredients.push(addedElement);
    switch (category) {
      case "Fermentable":
        fermentables.push(addedElement);
        break;
      case "Hop":
        hops.push(addedElement);
        break;
      case "Yeast":
        yeasts.push(addedElement);
        break;
      default:
        other.push(addedElement);
        break;
    }

  }

  function listType(array) {
    var result = [];
    var counter = -1;
    if (array.length > 0) {
      for (let index = 0; index < array.length; index++) {
        if (array[index] !== null) {
          result[index - counter] =
            (
              <div className={'row ' + styles.round}>
                <div className={'col-6 ' + styles.col} style={{ backgroundColor: "white" }}><div className={styles.text}>{array[index].ingredient}</div></div>
                <input className={'col-4 ' + styles.col + " " + styles.textInput} style={{ backgroundColor: "white" }} type="text" name="quantity"
                  onChange={event => setQuantityEdit(event.target.value, array, index, array[index].ingredient) } defaultValue={array[index].quantity} />
                <div className={'col-1 ' + styles.col} onClick={(e) => deleteIngredient(index, array, e)}><img src={garbage} className={styles.removeButton} /></div>
                </div>);
        } else {
          counter++;
        }

      }
      if (counter === array.length - 1) {
        return null;
      }
      return result;
    }
    return null;
  }

  function inventory(array) {
    var ferm = listType(fermentables);
    var hp = listType(hops);
    var yt = listType(yeasts);
    var ot = listType(other);
    if (array.length > 0) {
      return (<>
        <div className={'row ' + styles.row}>
          {ferm !== null ? <div className={'col-6 ' + styles.styleCategory}>
            <div className='row'>
              <h5 className={styles.category}>Fermentables</h5>
            </div>
            {ferm}
          </div> :  <div className={'col-6 ' + styles.invisible}></div>}
          {hp !== null ? <div className={'col-6 ' + styles.styleCategory}>
            <div className='row'>
              <h5 className={styles.category}>Hops</h5>
            </div>
            {hp}
          </div> :   <div className={'col-6 ' + styles.invisible}></div>}
          {yt !== null ? <div className={'col-6 ' + styles.styleCategory}>
            <div className='row'>
              <h5 className={styles.category}>Yeasts</h5>
            </div>
            {yt}
          </div> :   <div className={'col-6 ' + styles.invisible}></div>}
          {ot !== null ? <div className={'col-6 ' + styles.styleCategory}>
            <div className='row'>
              <h5 className={styles.category}>Other</h5>
            </div>
            {ot}
          </div> : <div className={'col-6 ' + styles.invisible}></div>}
        </div>
      </>);
    } else {
      return null;
    }
  }

  const [show, setShow] = useState(false);

  const [ingredient, setIngredient] = useState('');
  const [quantity, setQuantity] = useState('');
  const [category, setCategory] = useState("Select Category");
  const [res, setResult] = useState("Result");

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (ingredient !== "" && quantity !== "" &&  containsOnlyNumbers(quantity)) {
      var addedElement = {
        ingredient: ingredient,
        quantity: quantity,
      };

      add(addedElement, category);
    }
    setCategory("Select Category");
    setIngredient('');
    setQuantity('');
    var invent = inventory(ingredients);
    setResult(invent);
    result = invent;
  };

  function setQuantityEdit(value, array, index, ingre) {
    ingredients.forEach(element => {
      if(ingre === element.ingredient){element.quantity = value;}
    });

      array[index].quantity = value;
      var invent = inventory(ingredients);
      setResult(invent);
      result = invent;
    

  }

  function deleteIngredient(index, array, e) {
    for (let i = 0; index < ingredients.length; i++) {
      if (ingredients[i] === array[index]) {
        ingredients[i] = null;
        break;
      }
    }
    nullCounter++;
    array[index] = null;
    var invent = inventory(ingredients);
    setResult(invent);
    result = invent;

  };


  function choose() {
    if (ingredients.length === 0 || ingredients.length === nullCounter || result === null) {
      return <h5 className={styles.advice2}>You don't have any items on your inventory, try adding one on the plus sign.</h5>;
    } else {
      return inventory(ingredients);
    }
  }



  return (
    <>
      <div className={styles.inventory}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className="row"><h1>BREW STATION</h1></div>
            <div className={"row " + styles.centerTitle}><h2>Brew beer like a pro</h2></div>
          </div>
        </div>
        <div className='row'>
          <div className={'col-9 ' + styles.textStyle}><h5>KEEP TRACK OF YOUR INVENTORY</h5></div>
          <div className={'col-1 ' + styles.padding} onClick={() => setShow(true)}><img src={addButton} className={styles.addButton} /></div>
          <Modal show={show} backgroundColor="#ede8e7" onHide={() => { setShow(false); setCategory("Select Category") }}>
            <h3 className={styles.padd}>Add an item to your inventory</h3>
            <h6 className={styles.advice}>Note that quantities should be in milliliters or grams</h6>
            <form onSubmit={handleSubmit}>
              <div className={'row ' + styles.padd}>
                <div className={'col-4 ' + styles.center}><input type="text" name="ingredient" placeholder="Name..." style={{ width: "100%" }} onChange={event => setIngredient(event.target.value)} value={ingredient} /></div>
                <div className={'col-4 ' + styles.center}><input type="text" name="quantity" placeholder="Quantity..." style={{ width: "100%" }} onChange={event => setQuantity(event.target.value)} value={quantity} /></div>
                <div className={'col-4 ' + styles.center}>
                  <DropdownButton
                    title={category}
                    autoClose={"inside"}
                    size="sm"
                    id={styles.dropdown}>
                    <Dropdown.Item className={styles.dropdownItem} eventKey="1" onClick={() => setCategory("Fermentable")}>Fermentable</Dropdown.Item>
                    <Dropdown.Item className={styles.dropdownItem} eventKey="2" onClick={() => setCategory("Hop")}>Hop</Dropdown.Item>
                    <Dropdown.Item className={styles.dropdownItem} eventKey="3" onClick={() => setCategory("Yeast")}>Yeast</Dropdown.Item>
                    <Dropdown.Item className={styles.dropdownItem} eventKey="3" onClick={() => setCategory("Other")}>Other</Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <div className={'row ' + styles.save}><button className={styles.saveButton} type="submit" onClick={() => setShow(false)}>Save</button></div>
            </form>
          </Modal>
        </div>
        <div className={styles.ingredientList}>
          {choose()}
        </div>
      </div>
    </>
  );
};

export default MyBrewstation;
