import React from "react";
import Modal from 'react-bootstrap/Modal';
import styles from "./modal.module.css";




function MyModal({ show, onHide, children, backgroundColor = "white" }) {
  return (
    <Modal  style={{borderRadius: "1rem"}} show={show} onHide={onHide} size="xl" centered contentClassName={styles.modal}>
      <Modal.Body  style={{backgroundColor: backgroundColor, borderRadius: "0.5rem"}}>
        {children}
      </Modal.Body>
    </Modal>
  );
}
export default MyModal;