import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Beers from './pages/Beers';
import Events from './pages/Events';
import SignUp from './pages/SignUp';
import Beer from './pages/Beer';
import MyBrewStation from './pages/MyBrewStation';

function App() {
	var beerExamples = [];
	function beers() {
		let myJson = require('./pages/beers.json');
		for (var i = 0; i < myJson.beers.length; i++) {
			var beer = myJson.beers[i];
			beerExamples.push(beer)
		}
	};
	var counter = 0;
	beers();
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='/beers' element={<Beers />} />
				<Route path='/events' element={<Events />} />
				<Route path='/mybrewstations' element={<MyBrewStation />} />
				<Route path='/sign-up' element={<SignUp />} />
				{beerExamples.map(elementBeer =>
				<Route path={'/beers/'+ elementBeer.name} element={<Beer beer={elementBeer} index={counter++} />} />)}
			</Routes>
		</Router>
	);
}

export default App;
