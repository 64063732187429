import React, { useState } from 'react';
import "./SignUp.css";
const SignUp = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    return (

        <div className='signUp-div'>
            <div>
                <form className='form'>
                    <label className='title'>SignUp</label>
                    <label>Username</label>
                    <input type="text" onChange={e => setUsername(e.target.value)}></input>
                    <label>Password</label>
                    <input type="password" onChange={e=>setPassword(e.target.value)}></input>
                    <label> Confirme password</label>
                    <input type="password"></input>
                </form>
                <button class="button" role="button">Confirme</button>
            </div>
        </div>

    )
};

export default SignUp;