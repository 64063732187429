import React from "react";
import styles from "./beersRecipes.module.css";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import logo from "./images/logo.png";
import { useState } from 'react';
import Examples from "../components/BeersAndRecipes";




const Beers = () => {
	const [category2, setCategory2] = useState("Alcohol");
	const [category1, setCategory1] = useState("Style");
	const [name, setName] = useState("Name...");
	const [sort, setSort] = useState("Name");


	return (
		<div className={"row " + styles.beersRecipes}>
			<div className={"col-2 " + styles.filter}>
				<div>
					<h5 className={styles.up}>Filter Results</h5>
					<div className={"row " + styles.style}>
						<DropdownButton
							title={category1}
							autoClose={"inside " + "outside"}
							id={styles.dropdown}>
							<Dropdown.Item className={styles.dropdownItem} eventKey="1" onClick={() => setCategory1("Ipa")}>Ipa</Dropdown.Item>
							<Dropdown.Item className={styles.dropdownItem} eventKey="2" onClick={() => setCategory1("Lager")}>Lager</Dropdown.Item>
							<Dropdown.Item className={styles.dropdownItem} eventKey="3" onClick={() => setCategory1("Stout")}>Stout</Dropdown.Item>
							<Dropdown.Item className={styles.dropdownItem} eventKey="4" onClick={() => setCategory1("Style")}>No preference</Dropdown.Item>
						</DropdownButton>
					</div>
					<div className={"row " + styles.alcool}>
						<DropdownButton
							title={category2}
							autoClose={"inside " + "outside"}
							id={styles.dropdown}>
							<Dropdown.Item className={styles.dropdownItem} eventKey="1" onClick={() => setCategory2("0% to 5%")}>0% to 5%</Dropdown.Item>
							<Dropdown.Item className={styles.dropdownItem} eventKey="2" onClick={() => setCategory2("5% to 9%")}>5% to 9%</Dropdown.Item>
							<Dropdown.Item className={styles.dropdownItem} eventKey="3" onClick={() => setCategory2("higher than 9%")}>higher than 9%</Dropdown.Item>
							<Dropdown.Item className={styles.dropdownItem} eventKey="4" onClick={() => setCategory2("Alcohol")}>No preference</Dropdown.Item>
						</DropdownButton>
					</div>
				</div>
			</div>
			<div className={"col-10 " + styles.warehouse}>
				<div className={styles.principal}>
					<div className="row">
						<div className={"col-6 text-center " + styles.image}><img src={logo} /></div>
						<div className={"col-6 text-center " + styles.text}>
							<div className={styles.title}>
								<div className="row"><h1>BREW STATION</h1></div>
								<div className="row"><h2>Brew beer like a pro</h2></div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.search}>
					<div className="row">
						<h2 className={styles.title}>SEARCH FOR BEERS AND RECIPES</h2>
					</div>
					<div className={"row " + styles.searchProps}>
						<div className="col-6">
							<div className="row">
								<div className="col-auto">Search:</div>
								<div className="col-auto"><input type="text" name="name" placeholder='Name...' onKeyDown={event => event.key === "Enter" ? setName(event.target.value) : setName("Name...")} /></div>
								<div className="col-auto"></div>
							</div>
						</div>
						<div className="col-6">
							<div className="row">
								<div className="col-auto">Sort by:</div>
								<div className="col-auto">
									<DropdownButton
										title={sort}
										autoClose={"inside outside"}
										id={styles.dropdown2}>
										<Dropdown.Item className={styles.dropdownItem} eventKey="1" onClick={() => setSort("Rating")}>Rating</Dropdown.Item>
										<Dropdown.Item className={styles.dropdownItem} eventKey="2" onClick={() => setSort("Difficulty")}>Dificulty</Dropdown.Item>
										<Dropdown.Item className={styles.dropdownItem} eventKey="3" onClick={() => setSort("Name")}>Name</Dropdown.Item>
									</DropdownButton>
								</div>
								<div className="col-auto"></div>
							</div>
						</div>
					</div>
					<div className={styles.beerExamples}>
						<Examples  filter1={category1}  filter2={category2} search={name} sort={sort}></Examples>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Beers;
