import React from "react";
import { NavLink } from "./NavbarElements";
import styles from "./navbar.module.css";


const Navbar = () => {
  return (
    <>
    <div className={styles.bar}>
      <div className="row">
        <div className="col-1">
          <NavLink to="/" activeStyle>
            Home
          </NavLink>
        </div>
        <div className="col-1">
          <NavLink to="/beers" activeStyle>
            Beers
          </NavLink>
        </div>
        <div className="col-1">
          <NavLink to="/events" activeStyle>
            Events
          </NavLink>
        </div>
        <div className="col-1">
          <NavLink to="/mybrewstations" activeStyle>
            MyBrewstation
          </NavLink>
        </div>
        <div className="col-5">
        </div>
        <div className="col-2">
          <NavLink to="/sign-up" activeStyle>
            Sign Up
          </NavLink>
        </div>
      </div>
    </div>
    </>
  );
};

export default Navbar;
