import React from "react";
import styles from "./beersRecipes.module.css";
import  "../../pages/images/beers/Ipa1.jpg"
import  "../../pages/images/beers/Ipa2.jpg"
import  "../../pages/images/beers/Lager1.jpg"
import  "../../pages/images/beers/Lager2.jpg"
import  "../../pages/images/beers/Stout1.jpg"
import  "../../pages/images/beers/Stout2.jpg"
import {useNavigate} from 'react-router-dom';




var beerExamples = [];
function beers() {
  let myJson = require('../../pages/beers.json');
  for (var i = 0; i < myJson.beers.length; i++) {
    var beer = myJson.beers[i];
    beerExamples.push(beer)
  }
};

beers();
function filter(filter1, filter2){
  var result = [];
  if(filter1 === "Style" && filter2 === "Alcohol"){
    return beerExamples;
  }
  if(filter2 === "Alcohol"){
    beerExamples.forEach(element => {
      if(element.style === filter1)
        result.push(element);
    });
    return result;
  }
  if(filter1 === "Style"){
    beerExamples.forEach(element => {
      if(filter2.charAt(0) === "h"){
        if(element.alcohol > 9){
          result.push(element);
        }
      }
      else if(0 === Number(filter2.charAt(0))){
        if(element.alcohol <= 5){
          result.push(element);
        }
      }
      else if(5 === Number(filter2.charAt(0))){
        if(element.alcohol > 5 && element.alcohol <= 9){
          result.push(element);
        }
      }
    });
    return result;
  }
  beerExamples.forEach(element => {
    if(element.style === filter1){
    if(filter2.charAt(0) === "h"){
      if(element.alcohol > 9){
        result.push(element);
      }
    }
    else if(0 === Number(filter2.charAt(0))){
      if(element.alcohol <= 5){
        result.push(element);
      }
    }
    else if(5 === Number(filter2.charAt(0))){
      if(element.alcohol > 5 && element.alcohol <= 9){
        result.push(element);
      }
    }
  }
  });
  return result;
}

function sortElements(sort){
  if(sort === "Name"){
    beerExamples.sort(function(a, b){
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
  }
  if(sort === "Rating"){
    beerExamples.sort(function(a, b){return a.rating - b.rating});

  }
  if(sort === "Difficulty"){
    beerExamples.sort(function(a, b){return a.difficulty - b.difficulty});
  }

}

function searchByName(search, array){
  var result = [];
  if(search !== "Name..."){
    let y = search.toLowerCase();
    array.forEach(element => {
      let x = element.name.toLowerCase();
      let position = x.search(y);
      if(position !== -1){
        result.push(element);
      }
    });
    return result;
  }
  return array;


}


function BeersRecipes({ filter1, filter2, search, sort}) {

  const navigate = useNavigate();
  const navigateBeer = (name) => {
    // 👇️ navigate to /beers
    navigate('/beers/' + name);
  };


  sortElements(sort);
  var res = searchByName(search, filter(filter1, filter2));
  function show(array){
    var show = [];
    array.forEach(element => {
      show.push(
      <div className={"col-4 " + styles.beer}>
        <div className={styles.beerDiv} onClick={() => navigateBeer(element.name)}>
          <div className={styles.image} style={{ backgroundImage: 'url(' + element.image + ')' }}></div>
          <div className={styles.beerName}>{element.name}</div>
          <div className={styles.beerDescription}>{element.description}</div>
          <div  className={styles.beerCar}>Rating: {element.rating}</div>
          <div  className={styles.beerCar}>Difficulty: {element.difficulty}</div>
          <div  className={styles.beerCar}>Alcohol: {element.alcohol}%</div>
        </div>
      </div>)
    });
    return show;
  }
    return (
    <div className="row">
      {show(res)}
    </div>
  );
}
export default BeersRecipes;