import React from 'react';
import styles from "./home.module.css";
import logo from "./images/logo.png";
import {useNavigate} from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();

  const navigateEvents = () => {
    // 👇️ navigate to /events
    navigate('/events');
  };

  const navigateInventory = () => {
    // 👇️ navigate to /mybrewstations
    navigate('/mybrewstations');
  };

  const navigateBeers = () => {
    // 👇️ navigate to /beers
    navigate('/beers');
  };

  return (
    <div className={styles.overflow}>
      <div className={styles.homeBegin}>
        <div className={styles.principal}>
          <div className="row">
            <div className="col-6 text-center"><img src={logo} /></div>
            <div className={"col-6 text-center " + styles.center}>
              <div className={styles.title}>
                <div className="row"><h1>BREW STATION</h1></div>
                <div className="row"><h2>Brew beer like a pro</h2></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'row ' + styles.options}>
        <div className={"col-3 " + styles.setMargin} onClick={navigateBeers}>
          <div className={'row ' + styles.recipiesNShop}></div>
          <div className={styles.text}>
            <h5>SEARCH FOR RECIPES AND BEERS</h5>
            <p>Scroll throught our collection of recipes
              and find the formula for all the beers you 
              always wanted to brew at home.
            </p>
          </div>
        </div>
        <div className={"col-3 " + styles.setMargin} onClick={navigateInventory}>
          <div className={'row ' + styles.inventory}></div>
          <div className={styles.text}>
            <h5>TRACK YOUR INVENTORY</h5>
            <p>Still got enough of all the good stuff?
              Keep track of your inventory and orders - 
              so you never run out of fuel anymore.
            </p>
          </div>
        </div>
        <div className={"col-3 " + styles.setMargin} onClick={navigateEvents}>
          <div className={'row ' + styles.events}></div>
          <div className={styles.text}>
            <h5>ATTEND EVENTS</h5>
            <p>Discover beer-tasting events in your
              area, meet up with community members
              and enjoy beer together. Cheers!
            </p>
          </div>
        </div>
      </div>
      <div className={"row " + styles.homeEnd}>
        <div className={'col-10 ' + styles.end}></div>
        <div className={'col-2 ' + styles.triangle}></div>
      </div>
    </div>
  );
};

export default Home;
