import React from "react";
import styles from "./beer.module.css";
import logo from "./images/logo.png";
import image0 from "../pages/images/Characteristics/image0.jpg"
import image1 from "../pages/images/Characteristics/image1.jpg"
import image3 from "../pages/images/Characteristics/image3.jpg"
import image2 from "../pages/images/Characteristics/image2.jpg"
import image5 from "../pages/images/Characteristics/image5.jpg"
import image4 from "../pages/images/Characteristics/image4.jpg"
import Modal from "../components/Modal";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';





function Beer({ beer, index }) {
  var img;
  switch (index) {
    case 0: img = image0;
      break;
    case 1: img = image1;
      break;
    case 1: img = image2;
      break;
    case 3: img = image3;
      break;
    case 4: img = image4;
      break;
    default: img = image5;
      break;
  }

  var comments = [];
  beer.comments.forEach(element => {
    comments.push(<h4>{element}</h4>)
  });

  const [show, setShow] = useState(false);
  const [quantity, setQuantity] = useState(0);

  function handleBuy() {
    alert("Bought " + quantity + " " + beer.name + ".")
  }

  const navigate = useNavigate();

  const nav = () => {
    navigate('/mybrewstations');
  };



  return (
    <div className={styles.all}>
      <div className={styles.principal}>
        <div className="row">
          <div className={"col-6 text-center " + styles.image}><img src={logo} /></div>
          <div className={"col-6 text-center " + styles.text}>
            <div className={styles.title}>
              <div className="row"><h1>BREW STATION</h1></div>
              <div className="row"><h2>Brew beer like a pro</h2></div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row " + styles.back}>
        <div className={"col-6 " + styles.align}>
          <div>
            <h1>{beer.name}</h1>
            <h5>{beer.description}</h5>
          </div>
          <div className={styles.ingredients}>
            <div className="row">
              <div className="col-6">
                <h2>Ingredients</h2>
                <h6>Malt: {beer.maltes}</h6>
                <h6>Hop: {beer.lupulo}</h6>
                <h6>Yeast: {beer.levedura}</h6>
              </div>
              <div className={"col-6 " + styles.but}>
                <button className={styles.Button} onClick={nav}>Check inventory</button>
              </div>
            </div>
          </div>
          <div className={styles.procedure}>
            <h2>Brewing Procedure</h2>
            <h6>{beer.procedure}</h6>
          </div>
        </div>
        <div className={"col-6 " + styles.align}>
          <div className={styles.images} style={{ backgroundImage: 'url(' + beer.image + ')' }}></div>
          <div className={styles.carateristics}><h1>Characteristics</h1></div>
          <div className={styles.car} style={{ backgroundImage: 'url(' + img + ')' }}></div>
        </div>
      </div>
      <div className={"row " + styles.padding + " " + styles.paddingBottom}>
        <div className="col-6"><h2>Rating: {beer.rating}</h2></div>
        <div className="col-6"><h2>Difficulty: {beer.difficulty}</h2></div>
      </div>
      <div className={"row " + styles.paddingComments}>
        <div className="col-6">
          <h2>Comments:</h2>
          <div className={styles.commentsColor}>{comments}</div>
        </div>
        <div className="col-6">
          <div className={styles.buy}>
            <div className={styles.color}>
              <div className={"row "}><h2>Don't want to brew yourself?</h2></div>
              <div className={"row " + styles.buyButton}>
                <div className="col-1"></div>
                <div className="col-10"><button onClick={() => setShow(true)}>Order now!</button></div>
                <div className="col-1"></div>
              </div>
              <Modal show={show} onHide={() => setShow(false)}>
                <div className={"row " + styles.padd}>
                  <div className={"col-6 "}>
                    <h3>Buy this beer</h3>
                    <input type="text" name="quantity" onChange={event => setQuantity(Number(event.target.value))} placeholder="Quantity..." />
                    <h6 className={styles.price}>Price: {beer.price * quantity}$</h6>
                    <button className={styles.buyButton2} onClick={() => { setShow(false); handleBuy() }}>Buy {quantity} beers</button>
                  </div>
                  <div className={"col-6 " + styles.imageBuy} style={{ backgroundImage: 'url(' + beer.image + ')' }}></div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
export default Beer;