import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const NavLink = styled(Link)`
text-emphasis: none;
text-decoration: none;
color: black;
&:hover {
  text-emphasis: none;
  text-decoration: none;
  color: black;
}
color: #808080;
display: flex;
align-items: center;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: #000000;
}
`;


