import React, { useState, useCallback, useMemo } from 'react';
import './events.css'
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents, LayersControl, useMapEvent, Rectangle } from 'react-leaflet'
import { useEventHandlers } from '@react-leaflet/core'
import L from 'leaflet'
import icon from "./images/marker.png";
import logo from "./images/logo.png";
import styles from "./events.module.css";
import Modal from 'react-bootstrap/Modal';

let latitude = 0;
let longitude = 0;
currentLocation();
function currentLocation() {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition((position) => {
			latitude = position.coords.latitude;
			longitude = position.coords.longitude;
		});
	} else {
		alert("Geolocation is not supported by this browser.");
	}
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
	var R = 6371; // Radius of the earth in km
	var dLat = deg2rad(lat2 - lat1);  // deg2rad below
	var dLon = deg2rad(lon2 - lon1);
	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
		Math.sin(dLon / 2) * Math.sin(dLon / 2)
		;
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	var d = R * c; // Distance in km
	return d;
}

function deg2rad(deg) {
	return deg * (Math.PI / 180)
}

const Events = () => {

	// Classes used by Leaflet to position controls
	const POSITION_CLASSES = {
		bottomleft: 'leaflet-bottom leaflet-left',
		bottomright: 'leaflet-bottom leaflet-right',
		topleft: 'leaflet-top leaflet-left',
		topright: 'leaflet-top leaflet-right',
	}

	const BOUNDS_STYLE = { weight: 1 }

	function MinimapBounds({ parentMap, zoom }) {
		const minimap = useMap()

		// Clicking a point on the minimap sets the parent's map center
		const onClick = useCallback(
			(e) => {
				parentMap.setView(e.latlng, parentMap.getZoom())
			},
			[parentMap],
		)
		useMapEvent('click', onClick)

		// Keep track of bounds in state to trigger renders
		const [bounds, setBounds] = useState(parentMap.getBounds())
		const onChange = useCallback(() => {
			setBounds(parentMap.getBounds())
			// Update the minimap's view to match the parent map's center and zoom
			minimap.setView(parentMap.getCenter(), zoom)
		}, [minimap, parentMap, zoom])

		// Listen to events on the parent map
		const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
		useEventHandlers({ instance: parentMap }, handlers)

		return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
	}

	function MinimapControl({ position, zoom }) {
		const parentMap = useMap()
		const mapZoom = zoom || 0

		// Memoize the minimap so it's not affected by position changes
		const minimap = useMemo(
			() => (
				<MapContainer
				style={{ height: 80, width: 80 }}
					center={parentMap.getCenter()}
					zoom={mapZoom}
					dragging={false}
					doubleClickZoom={false}
					scrollWheelZoom={false}
					attributionControl={false}
					zoomControl={false}>
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					<MinimapBounds parentMap={parentMap} zoom={mapZoom} />
				</MapContainer>
			),
			[],
		)

		const positionClass =
			(position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
		return (
			<div className={positionClass}>
				<div className="leaflet-control leaflet-bar">{minimap}</div>
			</div>
		)
	}

	const [result, setResult] = useState();
	function eventsNear(array) {
		var list = [];
		for (let index = 0; index < array.length; index++) {
			var distance = getDistanceFromLatLonInKm(latitude, longitude, array[index].lat, array[index].lng).toFixed(2);
			if (distance <= 20.00) {
				list[index] =
					(<div className={'row ' + styles.padd}>
						<div className={'col-4 ' + styles.border} style={{ paddingBottom: '10px' }}>{array[index].name}</div>
						<div className={'col-8 ' + styles.border} style={{ paddingBottom: '10px' }}>Distance to event {distance} km</div>
					</div>);
			}
		}
		return list;
	}

	function choose() {
		if (markers.length === 0) {
			return <h5>No events nearby!!!</h5>;
		} else if (typeof result === "undefined") {
			return eventsNear(markers);
		} else {
			return result;
		}
	}

	const [markers, setMarkers] = useState([{name: "exemplo", description: "exemplo", date: "22/08/2022", organizer: "Presidente NUJola", lat: 38.7452, lng: -9.1604, reserve:false}]);

	function checkForCities(city) {
		let myJson = require('.//pt.json');
		for (var i = 0; i < myJson.cities.length; i++) {
			var cities = myJson.cities[i];
			if (cities.city === city) {
				setMapCenter([cities.lat, cities.lng]);
				setMapZoom(10); //to zoom on city location
			}
		}
	};

	const removeMarker = marker => {
		for (let index = 0; index < markers.length; index++) {
			if (markers[index] === marker) {
				markers.splice(index, 1);
				setMarkers(markers);
				break;
			}
		}
	}

	function SelectLocation() {
		useMapEvents({
			click(e) {
				setLat(e.latlng.lat);
				setLng(e.latlng.lng);
				//setMapCenter([e.latlng.lat, e.latlng.lng]);
				//setMapZoom(9);
				//setResult(eventsNear(mapMarkers));
			}

		});
		return (
			<React.Fragment>
				<Marker position={[lat, lng]} icon={markerIcon}>

				</Marker>
			</React.Fragment>
		);
	}

	function LocationMarkers() {
		return (
			<React.Fragment>
				{markers.map((marker, index) =>
					<Marker position={marker} icon={markerIcon}>
						<Popup minWidth={300}>
							<div className={styles.popUpGroup}>
								<div className='row'>
									<div className='col-4'>Name:</div>
									<div className='col-8'><input type="text" name="name" style={{ width: "75%" }} defaultValue={marker.name} /></div>
								</div>
								<div className='row'>
									<div className={'col-4 ' + styles.popup}>Description:</div>
									<div className={'col-8 ' + styles.popup}><input type="text" name="description" style={{ width: "75%" }} defaultValue={marker.description}/></div>
								</div>
								<div className='row'>
									<div className={'col-4 ' + styles.popup}>Date:</div>
									<div className={'col-8 ' + styles.popup}><input type="text" name="date"  style={{ width: "75%" }} defaultValue={marker.date}/></div>
								</div>
								<div className='row'>
									<div className={'col-4 ' + styles.popup}>Organizer:</div>
									<div className={'col-8 ' + styles.popup}><input type="text" name="date" style={{ width: "75%" }} defaultValue={marker.organizer}/></div>
								</div>
								<div className={'row ' + styles.popup} onClick={() => reserve(index)}><button disabled={markers[index].reserve}>{markers[index].reserve ? "Reserved" : "Reserve"}</button></div>
							</div>
						</Popup>
					</Marker>)}
			</React.Fragment>
		);
	}

	function reserve(index) {
		let newArr = [...markers];
		newArr[index].reserve = true;
		setMarkers(newArr);
	}

	const [center, setMapCenter] = useState([38.7452, -9.1604]);
	// This state is used to the zoom attribute of MapContainer component
	const [zoom, setMapZoom] = useState(6);

	const onClickHandler = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				let lat = position.coords.latitude;
				let long = position.coords.longitude;
				setMapCenter([lat, long]); //to go to current location (not very precise) (if using brave location does not work properly)
				setMapZoom(10); //to zoom on current location
			}, error, options);
		} else {
			alert("Geolocation is not supported by this browser.");
		}

	};

	function error(err) {
		alert(`ERROR(${err.code}): ${err.message}`);
	}

	var options = {
		enableHighAccuracy: true, timeout: 10 * 1000 * 1000, maximumAge: 0
	};


	function ChangeView({ center, zoom }) {
		const map = useMap();
		map.setView(center, zoom);
		return null;
	}

	const markerIcon = new L.Icon({
		iconUrl: icon,
		iconSize: [35, 35],
		iconAnchor: [17, 46], //[Left/right, top/bottom]
		popupAnchor: [1.5, -46]
	})

	function add(addedElement) {
		markers.push(addedElement);
		setResult();
	  }

	const [show, setShow] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');
	const [date, setDate] = useState('');
	const [organizer, setOrganizer] = useState('');
	
	const handleSubmit = event => {
		event.preventDefault();
		
	
		if (name !== "" && description !== "" && date !== "" && organizer !== "" && lat !== "" && lng !== "") {
		  var addedElement = {
			name: name,
			description: description,
			date: date,
			organizer: organizer,
			lat: lat,
			lng: lng,
			reserve: false
		  };
		  add(addedElement);
		  setResult(eventsNear(markers));
		  alert('Event created successfully!');
		  setShow(false);
		} else {
			alert('Please fill every field before submitting.');
		}
		setName('');
		setDescription('');
		setDate('');
		setOrganizer('');
		setLat('');
		setLng('');
	  };

	return (
		<div className={styles.overflow}>
			<div className={styles.principal}>
				<div className="row">
					<div className={"col-6 text-center " + styles.image}><img src={logo} /></div>
					<div className={"col-6 text-center " + styles.text}>
						<div className={styles.title}>
							<div className="row"><h1>BREW STATION</h1></div>
							<div className="row"><h2>Brew beer like a pro</h2></div>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className={'col-11 ' + styles.discover}><h3>Discover beer events nearby</h3></div>
				<div className={'col-1 ' + styles.padding} onClick={() => setShow(true)}><button className={styles.addButton}></button></div>
				<Modal show={show} onHide={() => setShow(false)} dialogClassName={styles.modal90} centered>
					<Modal.Header closeButton>
          				<Modal.Title>
						  Add an event
          				</Modal.Title>
					</Modal.Header>
					<Modal.Body>
            			<form onSubmit={handleSubmit}>
							<div className='row'>
								<div className='col-6'>
									<h6>Name</h6>
              						<div className='row'>
                						<div className='col-10'><input className={styles.textBox} type="text" name="name" placeholder="Name" onChange={event => setName(event.target.value)} value={name}/></div>
              						</div>
									<br></br>
									<h6>Description</h6>
									<div className='row'>
                						<div className='col-10'><textarea className={styles.textBox} type="textarea" name="description" placeholder="Description" onChange={event => setDescription(event.target.value)} value={description} style={{ height: "30vh" }}></textarea></div>
              						</div>
									<br></br>
									<h6>Date</h6>
									<div className='row'>
                						<div className='col-10'><input className={styles.textBox} type="date" name="date" placeholder="DD/MM/YYYY" onChange={event => setDate(event.target.value)} value={date}/></div>
              						</div>
									<br></br>
									<h6>Organizer</h6>
									<div className='row'>
                						<div className='col-10'><input className={styles.textBox} type="text" name="organizer" placeholder="Organizer" onChange={event => setOrganizer(event.target.value)} value={organizer}/></div>
              						</div>
									<br></br>
									<h6>Click on the map to select a location</h6>
									<div className='row'>
                						<div className={'col-9 ' + styles.border}> {"Lat: " + lat + ", Lng: " + lng}</div>
                						<div className={'col-2 ' + styles.paddingADD}><button className={styles.addButton} type="submit"></button></div>
              						</div>
								</div>
								<div className='col-6'>
									<MapContainer center={center} zoom={zoom} scrollWheelZoom={true} maxZoom={15} doubleClickZoom={false}>
										<LayersControl position="bottomleft">
											<LayersControl.BaseLayer checked name="OpenStreetMap">
												<TileLayer
												url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
												/>
											</LayersControl.BaseLayer>
											<LayersControl.BaseLayer name="Satellite View">
												<TileLayer
													url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
													maxZoom={20}
													subdomains={['mt1', 'mt2', 'mt3']}
												/>
											</LayersControl.BaseLayer>
										</LayersControl >
										<SelectLocation />
										<MinimapControl position="topright" />
									</MapContainer>
								</div>
							</div>
           				</form>
					</Modal.Body>
          		</Modal>
			</div>
			<div className={'row ' + styles.paddingText}>
				<div className={'col-6 ' + styles.centerEventList}>List of events nearby</div>
				<div className='col-6'>
					<div className={'row ' + styles.paddingSearches}>
						<div className={'col-auto ' + styles.searchCity}>Search for city:</div>
						<input className={'col-4 ' + styles.input} type="text" name="city" placeholder='City'
							onChange={event => checkForCities(event.target.value)} />
						<div className='col'><button className={styles.useLocation} onClick={onClickHandler}>Use your location</button></div>
					</div>
				</div>
			</div>
			<div className={'row ' + styles.padding}>
				<div className='col-6'>{choose()}</div>
				<div className='col-6'>
					<MapContainer center={center} zoom={zoom} scrollWheelZoom={true} maxZoom={15} doubleClickZoom={false}>
						<ChangeView center={center} zoom={zoom} />
						<LayersControl position="bottomleft">
							<LayersControl.BaseLayer checked name="OpenStreetMap">
								<TileLayer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								/>
							</LayersControl.BaseLayer>
							<LayersControl.BaseLayer name="Satellite View">
								<TileLayer
									url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
									maxZoom={20}
									subdomains={['mt1', 'mt2', 'mt3']}
								/>
							</LayersControl.BaseLayer>

						</LayersControl >
						<LocationMarkers />
						<MinimapControl position="topright" />
					</MapContainer>
				</div>
			</div>
		</div>
	);

};

export default Events;